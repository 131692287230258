export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyCfi3jEmtF46EfhA3d249hFN1-kGWdyw6E",
        authDomain: "helaba.cwl.camp"
    },
    region: 'europe-west1',
    brain_base_url: 'https://helaba.api.deloitte.camp',
    default_app_lang: "de-DE",
    breadcrumbs: {
        assetOwner: {
            aoh: 'AI Werkstatt',
            assetManagement: 'App Verwaltung',
            principleAssetQuestions: 'Grundlegende Information',
            offerRequestDetail: 'Offer request detail',
            assetPreview: 'Vorschau',
        },
        store: {
            deloitteAiCatalog: 'Helaba Katalog',
            checkout: 'Checkout',
            standingOrderDetail: 'Standing order detail',
            storefront: 'App Store',
            orderHistory: 'Bestellübersicht',
            myEntitlements: "Meine Apps"
        },
    },
    aoh_url: 'https://aoh.helaba.cwl.camp/',
    store_url: 'https://helaba.cwl.camp/',
    api_base_url: 'https://helaba.api.deloitte.camp',
    authParams: ['oidc.helaba', 'oidc.campfire'],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    asset_redirect_uri: {},
    showInvitatio: false,
    hidePackagesAndPlans: true,
    hideContracting: true,
    hideSupport: true,
    aohQuestions: {
        hide_onboardingWelcomePopup: true,
        business_hideDelegationRadio: true,
        business_hideServiceOfferings: true,
        business_hideIsIndustryAligned: true,
        business_hideIndustries: true,
        business_hideAlliances: true,
        business_hideRevenueForm: true,
        business_hideGeographyQuestion: true,
        business_hideBusinessLineAlignment: true,
        technical_hideContainerizedQuestion: true,
        technical_hideInformOnboardingTeamButton: true,
        hideBeforeYouStartPopup: true,
        business_hideOfficeAddress: true
    },
    hideBarrierSuccessPopup: true,
    postBarrierQuestionsRoute: ['technical','onboarding'],
    onboarding: {
        displayVideo: false,
        video_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4',
        development: {
            frontend_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf',
            backend_url: 'https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse',
            business_support_email: 'mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace',
        },
    },
    barrierQuestionsAbort: {
        mailAddress: 'Support-Helaba-AI@helaba.de',
    },
    support: {
        ccEmail: "Support-Helaba-AI@helaba.de",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://helaba-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.helaba.cwl.camp/workflow-selector"
        }
    }
};
